import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  BlackGridTheme,
  Color,
  GrayGridTheme,
} from "../../../../../../data/style/variables"
import {
  Paragraph,
  TitleH2,
  TitleH3,
} from "../../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"
import { Bold } from "../../../../../general/Bold/Bold"

export const Platforms: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      mxfPhoneImage: file(relativePath: { eq: "MXF/3.png" }) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      mxfDesktopImage: file(relativePath: { eq: "MXF/mxf-desktop.JPG" }) {
        childImageSharp {
          sizes(quality: 90) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
        }}
        title={
          <TitleH2 color={Color.Black}>
            Applicaties die je op <Bold>al je apparaten</Bold> kunt gebruiken.
          </TitleH2>
        }
      >
        <Section sectionElementProps={{ noPaddingTop: true }}>
          <TypographyAnimation>
            <Paragraph color={Color.Black}>
              Onze apps en websites draaien op alle platformen voor zowel
              Android als iOS. Hierdoor kunnen we apps realiseren die zonder
              extra kosten - voor apperatuur - gelijk in gebruik genomen kunnen
              worden door uw medewerkers.
            </Paragraph>
            <TitleH3 color={Color.Black}>Smartphones & Tablets</TitleH3>
            <Paragraph color={Color.Black}>
              Het gebruiken van smartphones voor onze apps is een goedkope optie
              voor uw bedrijf. Omdat meeste medewerkers vaak al over een
              smartphone beschikken hierdoor hoeft u{" "}
              <Bold background={Color.Black}>geen extra kosten</Bold> te maken
              voor hardware.
            </Paragraph>
            <Paragraph color={Color.Black}>
              Sommige apps werken beter op een tablet. We leveren apps en
              tablets met schokwerende hoesen. Een voorbeeld hiervan is bij een
              van onze klanten die een tablet in gebruik hebben met daarop een
              checklist applicatie. Een tablet is hierbij handig omdat het
              scherm groter is en je hierdoor meer overzicht hebt.
            </Paragraph>
            <TitleH3 color={Color.Black}>Desktops</TitleH3>
            <Paragraph color={Color.Black}>
              We maken onze apps en websites standaard ook voor desktops
              hierdoor kunt u uw apps altijd en overal gebruiken.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <div>
            <img
              width={875}
              src={data.mxfPhoneImage.childImageSharp.sizes.src}
            />
          </div>
          {/* <img src={data.mxfDesktopImage.childImageSharp.sizes.src} /> */}
        </Section>
        {/* <Section></Section> */}
      </Grid>
    </ContainerElement>
  )
}
