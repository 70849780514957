import { Power3, TweenLite } from "gsap"
import React, { useEffect, useMemo, useRef, useContext } from "react"
import {
  Color,
  HeroGridTheme,
  GrayGridTheme,
} from "../../../data/style/variables"
import { CtaArrow, Rotations, Sizes } from "../CtaArrow/CtaArrow"
import { Grid } from "../Grid/Grid"
import { RevealBlockAnimation } from "../RevealBlockAnimation/RevealBlockAnimation"
import { Section } from "../Section/Section"
import { LetterSpanElement } from "./styled"
import {
  InfoSpan,
  TitleH1,
  Subtitle,
  MonoSubtitle,
} from "../Typography/Typography"
import {
  BottomBlockContainerElement,
  ContainerElement,
  CtaArrowContainerElement,
  HeroInnerContainerElement,
  TechnicalDetailsElement,
  LetterContainerElement,
} from "./styled"
import CtaArrowAnimation from "../CtaArrowAnimation"
import { TypographyAnimation } from "../TypographyAnimation/TypographyAnimation"
import { PageContext } from "../../context/PageManager/PageManager"

type ProductHeroProps = {
  title: string
  subTitle: string
  background?: string
  technicalDetails: string
  pageWidget?: JSX.Element
  infoTexts: string[]
}

export const ServiceHero: React.FC<ProductHeroProps> = ({
  title,
  background,
  technicalDetails,
  pageWidget,
  infoTexts,
  subTitle,
}) => {
  const pageTitleRef = useRef<HTMLDivElement>(null)
  const pageTechnicalDetail = useRef<HTMLDivElement>(null)
  const pageContext = useContext(PageContext)

  const memoizedGridElementProps = useMemo(
    () => ({
      noPadding: true,
      background: background ? background : undefined,
      baseHeight: "100vh",
      ...GrayGridTheme,
      noAutoHeightOnMobile: true,
      noPaddingBottom: true,
    }),
    [background]
  )

  useEffect(() => {
    if (pageTitleRef.current && pageContext.pageLoaded) {
      TweenLite.fromTo(
        pageTitleRef.current,
        1.2,
        { opacity: 0, x: -75 },
        { opacity: 1, x: 0, ease: Power3.easeOut }
      )
    }
    if (pageTechnicalDetail.current && pageContext.pageLoaded) {
      TweenLite.fromTo(
        pageTechnicalDetail.current,
        1.2,
        { opacity: 0, y: -25 },
        { opacity: 1, y: 0, ease: Power3.easeOut }
      )
    }
  }, [pageContext.pageLoaded])

  return (
    <ContainerElement>
      {/* <TextureOverlayElement src={crossSvg} /> */}
      <Grid gridElementProps={memoizedGridElementProps}>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          <HeroInnerContainerElement>
            <LetterContainerElement>
              <LetterSpanElement>{title.substr(0, 1)}</LetterSpanElement>
            </LetterContainerElement>
            <TechnicalDetailsElement ref={pageTechnicalDetail}>
              {technicalDetails}
            </TechnicalDetailsElement>
            <div ref={pageTitleRef}>
              <RevealBlockAnimation background={Color.Black}>
                <TitleH1 color={Color.Black}>{title}</TitleH1>
              </RevealBlockAnimation>
              <TypographyAnimation>
                <MonoSubtitle color={Color.Black}>{subTitle}</MonoSubtitle>
              </TypographyAnimation>
            </div>
          </HeroInnerContainerElement>
          <TypographyAnimation>
            <BottomBlockContainerElement>
              <InfoSpan color={Color.Black}>{infoTexts[0]}</InfoSpan>
            </BottomBlockContainerElement>
          </TypographyAnimation>
        </Section>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          <TypographyAnimation index={1}>
            <BottomBlockContainerElement>
              <InfoSpan color={Color.Black}>{infoTexts[1]}</InfoSpan>
            </BottomBlockContainerElement>
          </TypographyAnimation>
        </Section>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          {pageWidget && pageWidget}
          <CtaArrowContainerElement>
            <CtaArrowAnimation>
              <CtaArrow
                color={Color.Black}
                rotation={Rotations.down}
                size={Sizes.large}
              />
            </CtaArrowAnimation>
          </CtaArrowContainerElement>
          <TypographyAnimation index={2}>
            <BottomBlockContainerElement>
              <InfoSpan color={Color.Black}>{infoTexts[2]}</InfoSpan>
            </BottomBlockContainerElement>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
