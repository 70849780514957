import React from "react"
import Layout from "../components/layout"
import { Web as WebComponent } from "../components/pages/services/Web/Web"
import SEO from "../components/seo"
import { Color, GrayGridTheme } from "../data/style/variables"

const WebAndApps = () => (
  <Layout navColor={Color.Black} borderColor={GrayGridTheme.lineColor}>
    <SEO
      description="Mobiele applicaties voor het verbeteren van uw bedrijfsprocessen."
      title="Apps & Websites"
      lang="nl"
    />
    <WebComponent />
  </Layout>
)

export default WebAndApps
