import React from "react"
import "typeface-roboto-mono"
import { ServiceHero } from "../../../general/ServiceHero/ServiceHero"
import { Footer } from "../../Home/sections/Footer/Footer"
import { ContainerElement, HeroContainerElement } from "./styled"
import { Other } from "./Other/Other"

type ServiceProps = {
  heroProps: {
    title: string
    technicalDetails: string
    subTitle: string
    infoTexts: string[]
  }
}

export const Service: React.FC<ServiceProps> = ({ heroProps, children }) => {
  return (
    <ContainerElement>
      <HeroContainerElement>
        <ServiceHero {...heroProps} />
      </HeroContainerElement>
      {children}
      {/* <Other/> */}
      <Footer spacing />
    </ContainerElement>
  )
}
