import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  BlackGridTheme, Color,


  FontFamily
} from "../../../../../../data/style/variables"
import arrowSvg from "../../../../../../images/SVG/Arrow 8.svg"
import {
  Paragraph, TitleH2, TitleH3,
  Typography, TypographyTypes
} from "../../../../../general/Typography/Typography"
import {
  ArrowElement, ContainerElement,


  WorkflowIndexContainer, WorkflowItemContainer, WorkflowItemImageElement
} from "./styled"

export const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      workflowImageOne: file(relativePath: { eq: "ServiceWorkflows/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workflowImageTwo: file(relativePath: { eq: "ServiceWorkflows/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      workflowImageThree: file(relativePath: { eq: "ServiceWorkflows/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...BlackGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
          noPaddingBottom: true
        }}
        title={
          <TitleH2 color={Color.White}>
            Disc maakt web en apps die aansluiten bij uw wensen.
          </TitleH2>
        }
      >
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation>
              <TitleH3 color={Color.White}>Uw behoefte vastellen.</TitleH3>
              <Paragraph color={Color.White}>
                De eerste stap is het vastellen van een probleem in uw
                bedrijfsproces. Disc staat u bij in het vinden en vastellen van
                de problemen waarvan u zelf geen weet heeft.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  01
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
              <WorkflowItemImageElement
                src={data.workflowImageOne.childImageSharp.fluid.src}
              />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={1}>
              <TitleH3 color={Color.White}>Oplossing vinden.</TitleH3>
              <Paragraph color={Color.White}>
                Na het vastellen van een probleem moet er een oplossing bedacht
                worden. De oplossing kan een app of website zijn. Disc zoekt dit
                voor u uit en komt met een passend advies.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  02
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
              <WorkflowItemImageElement
                src={data.workflowImageTwo.childImageSharp.fluid.src}
              />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={2}>
              <TitleH3 color={Color.White}>Applicatie toepassen.</TitleH3>
              <Paragraph color={Color.White}>
                Na het ontwerpen van de oplossing gaan we aan de slag en
                zal u met het resultaat het gevonden probleem kunnen tackelen.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  03
                </Typography>
              </WorkflowIndexContainer>
              {/* This emtpy div is for the animation, without it the WorkflowItemImageElement will animate to soon. */}
              <div></div>
              <WorkflowItemImageElement
                src={data.workflowImageThree.childImageSharp.fluid.src}
              />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
