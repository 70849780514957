import styled from "styled-components/macro"
import { Color } from "../../../data/style/variables"

type ContainerElementProps = {
  src: string
}

export const TextureOverlayElement = styled.div<ContainerElementProps>`
  background-image: url('${props => props.src}');
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  opacity: .15;
  background-size: 60px;
`

export const ContainerElement = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

export const TechnicalDetailsElement = styled.div`
  position: absolute;
  transform: rotate(90deg);
  left: -185px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: "Roboto Mono", sans-serif;
  color: black;
  opacity: 0;
`

export const HeroInnerContainerElement = styled.div`
  height: 100%;
  padding-left: 25px;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    width: 200%;
  }
  overflow: hidden;
`

export const CtaArrowContainerElement = styled.div`
  position: absolute;
  bottom: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`

export const BottomBlockContainerElement = styled.div`
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    display: none;
  }
`

export const LetterContainerElement = styled.div``

export const LetterSpanElement = styled.span`
  color: rgba(0, 0, 0, 0.05);
  font-size: 90.75rem;
  position: absolute;
  left: 0;
  top: 150px;
  max-width: 90%;
  @media (min-width: 769px) {
    overflow: hidden;
    bottom: 0;
    top: unset;
  }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
`
