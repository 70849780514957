import React from "react"
import "typeface-roboto-mono"
import { Footer } from "../../Home/sections/Footer/Footer"
import { Service } from "../Service/Service"
import { About } from "./sections/About/About"
import { Platforms } from "./sections/Platforms/Platforms"

export const Web: React.FC = () => {
  return (
    <Service
      heroProps={{
        title: "Apps & Websites",
        technicalDetails: "Online - Cloud - Mobiel - PWA",
        subTitle:
          "Mobiele applicaties voor het verbeteren van uw bedrijfsprocessen.",
        infoTexts: ["Online", "Volledig in de Cloud", "Mobiel & Desktop"],
      }}
    >
      <About />
      <Platforms />
    </Service>
  )
}
